import store from 'bui/util/store'
import {Collection, Model} from 'backbone'
import {colorScheme} from 'bui/util/color-scheme'
import realtime from '../lib/realtime'
import sync, {syncBackboneCollection} from 'bui/realtime/client/sync'

export const Types = [
    'superadmin',
    'admin',
    'helper',
    'viewer'
]

class Users extends Collection {
    get url(){ return '/api/user'}
    get model(){ return User }

    constructor(){
        super()
        this.realtimeSync = sync(this.url, this)
        this.realtimeSync.connect()
        this.add(user)

        // watch for online status changes
        realtime.socket.on('/user', resp=>{
            let user = this.get(resp.id)
            if( user )
                user.set(resp)
        })
    }

    onSync(data){
        syncBackboneCollection.call(this, data)
    }

    // get online(){
    //     return this.filter(m=>m.isOnline)
    // }

    fetch(opts={}){
        opts.merge = true
        return super.fetch(opts)
    }

    parse(resp){
        // first user will always be the logged in user
        // set singleton user object ID so the attributes will be merged
        user.id = resp[0].id
        user.set('id', resp[0].id)
        return resp
    }

    inGroup(group){
        let Group = userGroups.get(group)
        if( !Group ) console.warning('Group does not exist: ', group)
        return Group ? Group.users : []
    }
}

export class User extends Model {

    urlRoot(){ return '/api/user' }

    constructor(){
        super(...arguments)
        
        // let cached = store('user')
        // if( cached )
        //     this.set(cached, {silent: true})

        // window.user = this // TEMP

        setTimeout(()=>{
            // this.applyTheme()
            if( this.isMe )
                this.on('change:settings', this.applyTheme)
        })
    }

    applyTheme(){

        let theme = this.get('settings.theme')
        // let themeColor = this.get('settings.theme_color')
        
        if( !theme && colorScheme.isDarkMode )
            theme = 'dark'
        
        colorScheme.setTheme(theme)
        // colorScheme.setAccent(themeColor)
    }

    get models(){ return {
        settings: UserSettings 
    }}

    get uname(){ return this.name }
    get name(){ return this.attributes.name ? this.get('name') : '' }
    get email(){ return this.attributes.email ? this.get('email') : '' }
    get isMe(){ return this === user }

    get isSuperadmin(){ return this.get('type') == 'superadmin' }
    get isAdmin(){ return this.get('type') == 'admin' || this.isSuperadmin }
    get isHelper(){ return this.get('type') == 'helper' || this.isAdmin }

    get initials(){
        return this.name.split(' ').map(s=>s[0]).slice(0,2).join('').toUpperCase()
    }

    logout(){
        store('user', null)
        window.location = '/logout'
    }

    async login(password, email){

        email = email || this.get('email')

        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        return fetch('/login', {
            method: 'post',
            credentials: 'include',
            body: formData
        }).then(r=>r.json()).then(resp=>{

            if( resp && !resp.error ){
                store('user', resp)
            }

            return resp
        })
    }

}

// singletons
export const user = new User()
export const users = new Users()
export default user

class UserSettings extends Model {
    
    // constructor(...args){
    //     console.log(args);
    //     args[0] = JSON.parse(args[0].id||'{}')
    //     super(...args)
    // }

    save(...args){
        this.set(...args)
        return this.parentModel.saveSync('settings', this.toJSON(), {patch: true})
    }
}