import './styles/login.less'

import { LitElement, html, css } from 'lit'
import device, {colorScheme} from 'bui/util/device'
import 'bui/elements/paper'
import 'bui/elements/btn'
import 'bui/elements/text'
import 'fc/control'
import user from './user'
import Icon from 'bui/elements/icon'

Icon.register(
    require('bui/elements/icon/legacy/eye-1.svg.html'),
    require('bui/elements/icon/legacy/eye-off.svg.html')
)

customElements.define('s-login', class extends LitElement{

    // static get styles(){return css`
        
    // `}

    constructor(){
        super()
        colorScheme.apply()

        this.q=new URLSearchParams(location.search)
    }

    firstUpdated(){
        document.body.classList.add('show')
    }

    createRenderRoot(){ return this }

    get isCustomerAccount(){
        // return true // TEST
        return !!location.host.match(/account/) || location.pathname == '/login-account'
    }

    render(){return html`

        <img src="/icons/bare-clear.png" class="logo">

        <form action="#">
        <b-paper overshadow>

            <form-control material="filled" label="Email" key="email" .value=${this.q.get('email')}>
                <input slot="control" type="email" id="email" pattern="email" autocomplete="email" value="">
            </form-control>

            ${this.isCustomerAccount?'':html`
            <form-control material="filled" label="Password" key="password">
                <input slot="control" type="password" id="password" @keydown=${this.onKeydown} autocomplete="password" value="">
                <b-icon name="eye-off" @click=${this.toggleSeePW} slot="suffix"></b-icon>
            </form-control>
            `}

            <b-btn block lg color="primary" @click=${this.login}>${this.isCustomerAccount?'Generate ':''}Login</b-btn>

            <p class="error"></p>

        </b-paper>

            ${this.isCustomerAccount?html`
                <br>
                <b-text dim sm italic>We'll send you an email with a link to your account</b-text>
            `:''}

        </form>
    `}

    toggleSeePW(e){
        let input = e.currentTarget.previousElementSibling
        input.type = input.type == 'text' ? 'password' : 'text'
        e.currentTarget.name = input.type == 'text' ? 'eye-1' : 'eye-off'
    }

    onKeydown(e){
        if( e.key == 'Enter' )
            e.currentTarget.parentElement.nextElementSibling.click()
    }

    login(e){

        let btn = e.currentTarget

        // already logging in
        if( btn.spin ) return

        let email = this.querySelector('#email')
        let pw = this.querySelector('#password')

        if( this.isCustomerAccount ){
            return this.loginCustomer(btn, email)
        }

        if( email.isInvalid || !email.value || !pw.value )
            return console.log('missing creds')

        btn.spin = true
        
        user.login(pw.value, email.value).then(resp=>{
            
            btn.spin = false

            if( resp.error ){
                btn.nextElementSibling.innerHTML = resp.error
                return
            }

            window.location = '/'+location.hash

        }, err=>{
            btn.spin = false
            btn.nextElementSibling.innerHTML = 'Problem signing in'
        })
        
    }

    loginCustomer(btn, email){
        if( btn.spin ) return
        if( email.isInvalid || !email.value ) return
        btn.spin = true
        window.location = `api/user/customer-login-create?email=`+email.value
    }

})

export default customElements.get('s-login')